import { tns } from "../../node_modules/tiny-slider/src/tiny-slider"

function getElementsByClassName(node, classname) {
    var a = [];
    var re = new RegExp('(^| )' + classname + '( |$)');
    var els = node.getElementsByTagName("*");
    for (var i = 0, j = els.length; i < j; i++)
        if (re.test(els[i].className)) a.push(els[i]);
    return a;
}

function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(o);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for (var i = 0, arr2 = new Array(len); i < len; i++) {
        arr2[i] = arr[i];
    }
    return arr2;
}

var videoPlayersElements = getElementsByClassName(document.body, 'main-slider__bg--video');
var videoPlayersSecondaryElements = getElementsByClassName(document.body, 'secondary-slider__bg--video');
var newsSliderWrap = getElementsByClassName(document.body, 'news-slider__wrap');
var videoPlayers = [];
var videoPlayersSecondary = [];

_toConsumableArray(videoPlayersElements).forEach(function (element, index) {
    var indexed = parseInt(element.dataset.slide);
    videoPlayers[indexed] = element.children[0];
    videoPlayers[indexed].loop = true;
    videoPlayers[indexed].muted = true;

    if (indexed == 1) {
        videoPlayers[indexed].play();
    }
});

_toConsumableArray(videoPlayersSecondaryElements).forEach(function (element, index) {
    var indexed = parseInt(element.dataset.slide);
    videoPlayersSecondary[indexed] = element.children[0];
    videoPlayersSecondary[indexed].loop = true;
    videoPlayersSecondary[indexed].muted = true;

    if (indexed == 1) {
        videoPlayersSecondary[indexed].play();
    }
});

function playPause(info, elementClass, elementArray) {
    var videoFile,
        num = info.displayIndex;

    if (info.cloneCount > 1) {
        num = num + 1;
    }

    videoFile = info.event.target.children[num].children[0];

    if (videoFile.classList.contains(elementClass)) {
        _toConsumableArray(elementArray).forEach(function (element) {
            if (element) {
                element.play();
            }
        });
    } else {
        _toConsumableArray(elementArray).forEach(function (element) {
            if (element) {
                element.pause();
            }
        });
    }
}

var playPauseMainSlide = function playPauseMainSlide(info, eventName) {
    playPause(info, 'main-slider__bg--video', videoPlayers);
};

var playPauseSecondarySlide = function playPauseSecondarySlide(info, eventName) {
    playPause(info, 'secondary-slider__bg--video', videoPlayersSecondary);
};
var foo = getElementsByClassName(document.body, 'main-slider');
if (foo.length !== 0) {
    var mainslider = tns({
        container: '.main-slider',
        items: 1,
        slideBy: 'page',
        prevButton: '.main-slider--prev',
        nextButton: '.main-slider--next',
        navPosition: 'bottom',
        arrowKeys: true,
        mouseDrag: true,
        touch: true,
        nav: true,
        animateDelay: "200ms",
        responsive: {
            0: {
                autoHeight: true,
            },
            729: {
                autoHeight: false,
            }
        }
    });
    mainslider.events.on('transitionEnd', playPauseMainSlide);
}

var bar = getElementsByClassName(document.body, 'secondary-slider');
if (bar.length !== 0) {
    var secondarySlider = tns({
        container: '.secondary-slider',
        items: 1,
        slideBy: 'page',
        prevButton: '.secondary-slider--prev',
        nextButton: '.secondary-slider--next',
        navPosition: 'bottom',
        arrowKeys: true,
        mouseDrag: true,
        touch: true,
        animateDelay: "200ms",
        responsive: {
            0: {
                autoHeight: true,
            },
            729: {
                autoHeight: false,
            }
        }
    });
    secondarySlider.events.on('transitionEnd', playPauseSecondarySlide);
}

var sim = getElementsByClassName(document.body, 'news-slider');
if (sim.length !== 0) {
    var newsSlider = tns({
        container: '.news-slider',
        items: 1,
        slideBy: 1,
        autoHeight: true,
        autoplay: true,
        autoplayHoverPause: false,
        autoplayTimeout: 10000,
        autoplayButton: ".news-slider__autoplayButton",
        speed: 800,
        navPosition: 'bottom',
        arrowKeys: true,
        mouseDrag: true,
        touch: true,
        nav: true,
        controls: false,
        onInit: function(info) {
            info.navItems[0].setAttribute("tabindex", "0");
            // console.log(info.navItems[0]);
        },
        responsive: {
            980: {
                items: 2,
                slideBy: 2
            }
        }
    });
}

MicroModal.init({
    onShow: function onShow(modal) {
        return modal.querySelector('#splash__modal--video').play();
    },
    onClose: function onClose(modal) {
        return modal.querySelector('#splash__modal--video').pause();
    },
    disableScroll: true
});